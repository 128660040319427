import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/connections": [3],
		"/connections/content": [4],
		"/connections/invite-upsell": [5],
		"/connections/privacy": [6],
		"/content": [7],
		"/dashboard": [8],
		"/design": [9],
		"/design/connections": [10],
		"/design/connections/connections": [11],
		"/design/connections/content": [12],
		"/design/connections/create-group": [13],
		"/design/connections/empty": [14],
		"/design/connections/invite-upsell": [16],
		"/design/connections/invite": [15],
		"/design/connections/privacy": [17],
		"/design/content": [18],
		"/design/enneagram": [19],
		"/design/loading": [20],
		"/design/modal": [21],
		"/design/signup": [22],
		"/design/signup/change-password": [23],
		"/design/signup/create-password-id": [25],
		"/design/signup/create-password": [24],
		"/design/signup/forgot-password-send": [27],
		"/design/signup/forgot-password": [26],
		"/design/signup/login": [28],
		"/design/soulprint": [29],
		"/design/survey": [30],
		"/design/survey/astrology": [31],
		"/design/survey/enneagram-cards": [32],
		"/design/survey/enneagram-instructions-01": [33],
		"/design/survey/enneagram-instructions-02": [34],
		"/design/survey/enneagram-paragraphs": [35],
		"/design/survey/enneagram-tiebreak-02": [37],
		"/design/survey/enneagram-tiebreak-intro": [38],
		"/design/survey/enneagram-tiebreak": [36],
		"/design/survey/gender": [39],
		"/design/survey/interests-01": [40],
		"/design/survey/interests-02": [41],
		"/design/survey/job": [42],
		"/design/survey/numerology": [43],
		"/design/survey/relationship": [44],
		"/design/survey/retake": [45],
		"/design/survey/signup": [46],
		"/design/survey/spiraldynamics-01": [47],
		"/design/survey/spiraldynamics-02": [48],
		"/enneagram": [49],
		"/forgot-password": [50],
		"/free-soulprint": [51],
		"/group": [52],
		"/login": [53],
		"/logout": [54],
		"/new-personality-test": [55],
		"/onboard": [56],
		"/payment": [57],
		"/personality-test": [58],
		"/premium": [59],
		"/primary": [60],
		"/relationship": [61],
		"/reset-password": [62],
		"/secondary": [63],
		"/soulprint": [64],
		"/teritiary": [65],
		"/workplace": [66]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';